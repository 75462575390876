import { useLocation, useParams } from 'react-router-dom';

import { EventApiServiceHooks } from '@/api/events';
import startCase from 'lodash/startCase';
import { useOrganizationById } from '@/api/organizations/crud/get-organization.api';

const { useEventById } = EventApiServiceHooks;

const pageTitles: { [key: string]: string } = {
  homepage: '',
  events: 'Events',
  event: 'Event',
  organizations: 'Organizations',
  users: 'Users',
  venues: 'venues',
  dataExplorer: 'Data Explorer',
  integrations: 'Integrations',
  support: 'Support',
  admin: 'Admin',
  createApplication: 'Create Application',
  updateApplication: 'Edit Application',
  createUser: 'Create User',
  updateUser: 'Edit User',
  profile: 'Profile',
  applications: 'Applications',
  organizationProfile: 'Organization Profile',
  organizationUsers: 'Organization Users',
  organizationApikeys: 'Organization Api Keys',
  organizationAnalytics: 'Organization Analytics',
  organizationWebhooks: 'Webhook Subscriptions',
  sessionFormats: 'Session Formats',
  posterFormats: 'Poster Formats',
  subsessionFormats: 'Subsession Formats',
  socialMediaTypes: 'Social Media Types',
  technicalSupport: 'Technical Support',
  contenttopics: 'Topics',
  contentcategories: 'Categories',
  contenttags: 'Tags',
};

const pathNames: { [key: string]: string } = {
  homepage: 'app',
  events: 'app events',
  organizations: 'app organizations',
  dataExplorer: 'app data_explorer',
  integrations: 'app integrations',
  admin: 'app admin',
  createApplication: 'auth create-application',
  updateApplication: 'auth update-application',
  createUser: 'auth create-user',
  updateUser: 'auth update-user',
  profile: 'auth profile',
  applications: 'app applications',
  users: 'app users',
  organizationProfile: 'app organizations profile',
  organizationUsers: 'app organizations users',
  organizationApikeys: 'app organizations apikeys',
  organizationAnalytics: 'app organizations analytics',
  organizationWebhooks: 'app organizations webhooks',
  sessionFormats: 'app session_formats',
  posterFormats: 'app poster_formats',
  subsessionFormats: 'app subsession_formats',
  socialMediaTypes: 'app social_media_types',
  technicalSupport: 'app technical_support',
  contenttopics: 'app content_topics',
  contentcategories: 'app content_categories',
  contenttags: 'app content_tags',
};

type PageSettings = Partial<{
  pageTitle: string;
}>;

/**
 * Support Multiple pages that use the same component
 * @returns {pageSettings}
 */
export default function useGetPageSettings() {
  const { pathname } = useLocation();
  const { eventId = '', organizationId = '' } = useParams();
  const pageSettings: PageSettings = {};
  const cleanPathname = pathname.split('/').join(' ').trim().toLowerCase();
  // app organizations followed by uuid
  const singleOrganizationMatch = new RegExp(
    /app organizations [0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/
  );
  // app events followed by uuid
  const singleEventMatch = new RegExp(
    /app events [0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/
  );
  // app organizations followed by uuid
  const webhookLogsMatch = new RegExp(
    /app organizations webhooks [0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/
  );
  const isWebhookLogsMatch = webhookLogsMatch.test(cleanPathname);
  const isSingleOrganizationPage = singleOrganizationMatch.test(cleanPathname);
  // "app admin" followed by uuid followed by more text
  const adminPagesMatch = new RegExp(
    /app admin [0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12} +/
  );
  const isAdminPages = adminPagesMatch.test(cleanPathname);
  const isSingleEventPage = singleEventMatch.test(cleanPathname);

  const { data: event } = useEventById(eventId, { enabled: !!eventId });
  const { data: organization } = useOrganizationById(organizationId, { enabled: !!organizationId });

  if (isSingleOrganizationPage) {
    pageSettings.pageTitle = organization?.fullName;
    document.title = `Fuzion: ${organization?.fullName}`;
    return pageSettings;
  }
  if (isWebhookLogsMatch) {
    pageSettings.pageTitle = 'Webhook Logs';
    document.title = 'Fuzion: Webhook Logs';
    return pageSettings;
  }
  if (isAdminPages) {
    pageSettings.pageTitle = pageTitles.admin;
    document.title = `Fuzion: ${pageTitles.admin}`;
    return pageSettings;
  }
  if (isSingleEventPage) {
    pageSettings.pageTitle = event?.fullName || pageTitles.event;
    document.title = `Fuzion: ${event?.fullName || pageTitles.event}`;
    return pageSettings;
  }
  switch (cleanPathname) {
    case pathNames.homepage:
      pageSettings.pageTitle = pageTitles.homepage;
      break;
    case pathNames.events:
      pageSettings.pageTitle = pageTitles.events;
      break;
    case pathNames.organizations:
      pageSettings.pageTitle = pageTitles.organizations;
      break;
    case pathNames.organizationProfile:
      pageSettings.pageTitle = pageTitles.organizationProfile;
      break;
    case pathNames.organizationUsers:
      pageSettings.pageTitle = pageTitles.organizationUsers;
      break;
    case pathNames.organizationApikeys:
      pageSettings.pageTitle = pageTitles.organizationApikeys;
      break;
    case pathNames.organizationAnalytics:
      pageSettings.pageTitle = pageTitles.organizationAnalytics;
      break;
    case pathNames.organizationWebhooks:
      pageSettings.pageTitle = pageTitles.organizationWebhooks;
      break;
    case pathNames.dataExplorer:
      pageSettings.pageTitle = pageTitles.dataExplorer;
      break;
    case pathNames.integrations:
      pageSettings.pageTitle = pageTitles.integrations;
      break;
    case pathNames.createApplication:
      pageSettings.pageTitle = pageTitles.createApplication;
      break;
    case pathNames.updateApplication:
      pageSettings.pageTitle = pageTitles.updateApplication;
      break;
    case pathNames.createUser:
      pageSettings.pageTitle = pageTitles.createUser;
      break;
    case pathNames.updateUser:
      pageSettings.pageTitle = pageTitles.updateUser;
      break;
    case pathNames.profile:
      pageSettings.pageTitle = pageTitles.profile;
      break;
    case pathNames.applications:
      pageSettings.pageTitle = pageTitles.applications;
      break;
    case pathNames.users:
      pageSettings.pageTitle = pageTitles.users;
      break;
    case pathNames.sessionFormats:
      pageSettings.pageTitle = pageTitles.sessionFormats;
      break;
    case pathNames.posterFormats:
      pageSettings.pageTitle = pageTitles.posterFormats;
      break;
    case pathNames.subsessionFormats:
      pageSettings.pageTitle = pageTitles.subsessionFormats;
      break;
    case pathNames.socialMediaTypes:
      pageSettings.pageTitle = pageTitles.socialMediaTypes;
      break;
    case pathNames.technicalSupport:
      pageSettings.pageTitle = pageTitles.technicalSupport;
      break;
    case pathNames.contenttopics:
      pageSettings.pageTitle = pageTitles.contenttopics;
      break;
    case pathNames.contentcategories:
      pageSettings.pageTitle = pageTitles.contentcategories;
      break;
    case pathNames.contenttags:
      pageSettings.pageTitle = pageTitles.contenttags;
      break;
    default:
      pageSettings.pageTitle = startCase(cleanPathname);
  }

  if (pageSettings.pageTitle) {
    document.title = `Fuzion: ${pageSettings.pageTitle}`;
  } else {
    document.title = `Fuzion Portal`;
  }

  return pageSettings;
}
