const scopeNameToSortOrderMap: Record<string, number> = {
  'organizations:read': 10,
  'organizations:write': 20,
  'organizations:delete': 30,

  'events:read': 40,
  'events:write': 50,
  'events:delete': 60,

  'integrations:read': 70,
  'integrations:write': 80,
  'integrations:delete': 90,

  'users:read': 100,
  'users:write': 110,
  'users:delete': 120,

  'applications:read': 130,
  'applications:write': 140,
  'applications:delete': 150,

  'webhooks:read': 160,
  'webhooks:write': 170,
  'webhooks:delete': 180,

  'presentation-data:read': 190,
  'presentation-data:write': 200,
  'presentation-data:delete': 210,

  'registration-data:read': 220,
  'registration-data:write': 230,
  'registration-data:delete': 240,

  'config-management:read': 250,
  'config-management:write': 260,
  'config-management:delete': 270,

  'presentation-data-management:read': 280,
  'presentation-data-management:delete': 290,

  'exhibitor-data:read': 300,
  'exhibitor-data:write': 310,
  'exhibitor-data:delete': 320,

  'content-management:read': 330,
  'content-management:write': 340,
  'content-management:delete': 350,

  'bi-reporting:read': 360,
};

export const sortScopeNamesByCustomOrder = (scopeNames: string[]): string[] => {
  if (!scopeNames || !scopeNames?.length) {
    return scopeNames;
  }
  return [...scopeNames].sort((a, b) => {
    const aOrder = scopeNameToSortOrderMap[a];
    const bOrder = scopeNameToSortOrderMap[b];
    return aOrder - bOrder;
  });
};

type TItemWithLabel = { label: string };

export const sortScopeItemsWithLabelByCustomOrder = <T extends TItemWithLabel>(
  scopes: T[] = []
): T[] => {
  if (!scopes || !scopes?.length) {
    return scopes;
  }
  return [...scopes].sort((a, b) => {
    const aOrder = scopeNameToSortOrderMap[a.label];
    const bOrder = scopeNameToSortOrderMap[b.label];
    return aOrder - bOrder;
  });
};
